<template>
    <div class="container">
        <b-row>
            <b-col cols="12" sm="6" class="mb-4 mt-3 mb-sm-0">
                <h1>Belediyeler</h1>
            </b-col>
        </b-row>
        <b-col>
            <b-row>
                <b-table responsive striped hover :fields="fields" :items="users">
                    <template v-slot:cell(firma_sey)="data">
                        <b-button class="badge" @click="sil(data.item.user_id)" size="sm" variant="danger"><b-icon-trash></b-icon-trash></b-button>
                        <b-button :to="'/belForm/' + data.item.user_id" class="badge ml-1" size="sm"><b-icon-search></b-icon-search></b-button>
                    </template>
                </b-table>
            </b-row>
        </b-col>
        <b-button variant="primary" class="ekleButton" to="/belForm">Yeni Ekle</b-button>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data(){
        return{
            fields : [
                {
                    "key" : "user_adsoyad",
                    "label" : "Belediye",
                    "thStyle": { width: '150px'},
                    "sortable": "true",
                },
                {
                    "key" : "user_eposta",
                    "label" : "E-Posta",
                    "thStyle": { width: '150px'}
                },
                {
                    "key" : "user_tel",
                    "label" : "Tel",
                    "thStyle": { width: '150px'}
                },
                {
                    "key" : "firma_sey",
                    "label" : "",
                    "thStyle": { width: '50px'},
                    "tdClass": "text-right"
                },
            ],
            rangeChange: null,
        }
    },

    mounted(){
        this.$store.dispatch("users", this.days);
    },

    computed: {
        users(){
            return this.$store.state.users.filter(c => c.user_rutbe == 2);
        },
    },

    methods: {
        sil(a){
            Swal.fire({
                title: 'Emin Misin?',
                text: "Kullanıcı sistemden kalıcı olarak silinecektir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet',
                cancelButtonText: 'Hayır',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("userDel", a)
                    .then(r => {
                        Swal.fire(
                            r.status ? 'Tebrikler!' : 'Hata oluştu!',
                            r.message,
                            r.status ? 'success' : 'warning'
                        )
                    })
                }
            })
        }
    },

}
</script>

<style scoped>
.ekleButton{
    position: fixed;
    right: 15px;
    bottom: 15px;
}
h1{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}
@media (max-width: 767px){
  .datePicker{
    width: 100%;
  }
}
</style>